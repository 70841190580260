@import "../../scss/mixin.scss";

nav {
    background-color: $brown;
    position: fixed;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    gap: 96px;
    padding: 18px 0 9px;
    a {
        text-decoration: none;
        width: 150px;
        display: flex;
        justify-content: center;
        align-content: center;
        p {
            text-align: center;
            color: $stone;
            font-weight: 300;
            font-family: 'Verona';
        }
        &.underline{
            text-decoration: underline;
            color: $beige;

        }
        &:hover{
            text-decoration: underline;
            color: $beige;
            
        }
    }
}

nav.mobile {
    padding: 0;
    width: 0;
    position: fixed;
    top: 0;
    right: 0;
    height: 48px;
    width: 100%;
    &.active {
        height: 100dvh;
    }
    .mob-logo {
        top: 18px;
        position: absolute;
    }
    .lines {
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: absolute;
        right: 24px;
        top: 18px;
        .line {
            width: 24px;
            height: 1px;
            background-color: $stone;
        }
    }
    .menu-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        
        a {
            width: 100%;
            &.underline{
                text-decoration: underline;
                color: $beige;
            }
        }
        
        h3 {
            width: 100%;
            text-align: center;
            
        }
    }

    .download {
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: absolute;
        bottom: 64px;
        left: 24px;
        .arrow-button{
            border: 1px solid $stone;
            svg{
                path{
                    fill: $stone;
                }
            }
        }
    }
}
