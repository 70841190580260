@import "../../scss/mixin.scss";

.home-one {
    .intro-animation {
        display: flex;
        height: 200vh;
        background-color: beige;
        .intro-overlay {
            width: 100%;
            height: 100%;
            background-color: #312927;
            position: absolute;
            z-index: 1000;
        }
        .image-wrapper {
            display: flex;
            position: relative; /* Changed to relative to ensure images stay inside the wrapper */
            height: 100vh;
            @include mobile {
            }
            width: 100%;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            perspective: 1000px;
        }

        img {
            width: 100%;
            height: 100vh;
            @include mobile {
            }
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: cover;
        }
        .brown,
        .light-green,
        .dark-green,
        .orange {
            will-change: transform;
            transform-style: preserve-3d; /* Added to allow 3D transform on images */
            display: block;
            @include mobile {
                transform: rotate(-90);
            }
        }
        .brown {
            z-index: 5;
            @include mobile {
                transform: rotate(-90);
            }
        }
        .light-green {
            z-index: 4;
            @include mobile {
                transform: rotate(-90);
            }
        }
        .dark-green {
            z-index: 3;
            @include mobile {
                transform: rotate(-90);
            }
        }
        .orange {
            z-index: 2;
        }
        .circle-copy {
            max-width: 80vw;
        }
        .circle {
            border-radius: 50%;
            background-color: #312927;
            height: 600px;
            aspect-ratio: 1 / 1;
            position: absolute;
            display: flex;

            justify-content: center;
            align-items: center;
            text-align: center;
            @include mobile {
                height: 400px;
            }
            .copy {
                width: 350px;
                @include mobile {
                    width: 300px;
                }
                .image-wrapper {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
            .copy-two {
                color: beige;
                opacity: 0;
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 54px;
                h1 {
                    padding-top: 30px;
                    font-size: 60px;
                    line-height: 1.2;
                    @include mobile {
                        font-size: 40px;
                    }
                }
                h3 {
                    width: 50%;
                }
            }
        }
    }
}
.home-two {
    background-color: $brown;
    height: 100%;
    display: flex;
    justify-content: center;
    .text-wrapper {
        width: 50%;
        height: 200vh;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        gap: 24px;
        @include mobile {
            height: fit-content;
            width: 100%;
            padding: 15% 32px 15%;
        }
    }
    .text-to-animate {
        color: $stone;
        text-align: center;

        height: 100%;
    }
}

.parallax-wrapper {
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: $brown;
}

.parallax-section {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
}
.home-three {
    height: 100vh;
    @include mobile {
        height: 50vh;
    }
    .main-image-wrapper {
        width: 100%;
        height: 100%;

        position: relative;
        background-size: cover;
        background-position: bottom;
        @include mobile {
            background-position: center;
        }
    }
    .parallax-image {
        @include mobile {
            margin-bottom: 0;
        }
    }
}

.home-four {
    position: relative;
    z-index: 4;
    background-color: $beige;
    height: 100vh;
    .nav-section {
        width: 100%;
        display: flex;
        border-bottom: 1px solid $brown;
        transition: background 0.5s ease-in;
        cursor: pointer;
        text-decoration: none;
        height: calc(100vh / 3);
        justify-content: space-between;
        .copy-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 22px 32px;
            width: calc(100% / 3 * 2);
            @include mobile {
                padding: 24px 16px;
                width: 100%;
                gap: 64px;
            }
        }
        .image-section {
            aspect-ratio: 467/260;
            background-size: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            transition: background 1s ease;
            @include mobile {
                display: none;
            }
        }
        .arrow-button {
            background-color: $beige;
        }
        &:hover {
            background-color: $orange;
            .image-section {
                background-size: 105%;
            }
            .arrow-button {
                border: 1px solid $brown;
                background-color: $brown;
                transition: background 0.5s ease;
                p {
                    color: $orange;
                }
                svg {
                    path {
                        transition: fill 0.5s ease;
                        fill: $orange;
                    }
                }
            }
        }
    }
}
