@import "../../scss/mixin.scss";

#Availability-gallery {
    height: 100%;
    width: 100%;
    background-color: $brick;
    .swiper-slide {
        height: 100%;
        width: 100%;
        .image-wrapper {
            aspect-ratio: 16/10;
            width: 100%;
            background-size: cover;
            background-position: bottom;
        }
    }
    .caption-wrapper {
        padding: 24px 32px 0;
        @include mobile {
            padding: 0;
        }
        h3 {
            color: #302927;
            text-transform: uppercase;
            font-family: "Futura Std";
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px; /* 75% */
            @include mobile {
                font-size: 24px;
                line-height: 30px;
                padding: 24px 24px 0;
            }
        }
    }

    .nav-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px;
        cursor: pointer;
        @include mobile {
            padding: 24px;
        }
        .next,
        .prev {
            cursor: pointer;
            @include mobile {
                svg {
                    width: 30px;
                }
            }
        }
        .next {
            transform: scale(-1, 1);
        }
    }
}
