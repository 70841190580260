@import "../../scss/mixin.scss";

.location-one {
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .video {
        position: absolute;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
    }

    .circle {
        z-index: 2;
        width: 600px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: $brown;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .copy {
            margin-top: calc(-59px / 2);
        }
        @include mobile {
            width: 400px;
            h1 {
                line-height: 46px;
                padding-top: 10px;
            }
            .copy {
                margin-top: -10px;
            }
        }
    }
}

.location-two {
    background-color: $blue;
    height: 200vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .text-wrapper {
        width: 800px;
        padding: 64px 32px 0;
        height: 200vh;
    }
    .text-to-animate {
        text-align: center;
        height: 100%;
    }
}

.location-four {
    padding: 80px 0;
    background-color: $brown;
    @include mobile {
        padding: 32px 0;
    }
    .gallery {
        padding: 32px;
        display: flex;
        gap: 32px;
        border-top: 1px solid $stone;
        @include mobile {
            padding: 24px;
        }
        .copy-wrapper {
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 32px 32px 0;
            @include mobile {
                width: 100%;
                padding: 0;
                width: 100%;
                gap: 16px;
            }
            .copy {
                display: flex;
                p {
                    max-width: 400px;
                    font-family: "Verona Serial";
                    font-style: normal;
                    font-weight: 400;
                    @include mobile {
                        font-size: 14px;
                    }
                }

                flex-direction: column;
                gap: 32px;
                @include mobile {
                    gap: 16px;
                    h3 {
                        font-size: 20px;
                    }
                }
            }
            .nav-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 16px;
                .place {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    @include mobile {
                        gap: 8px;
                        h3 {
                            font-size: 20px;
                        }
                    }
                    p {
                        span {
                            font-size: 64px;
                            font-family: "Verona";
                            line-height: 70px;
                            @include mobile {
                                font-size: 42px;
                                line-height: 42px;
                            }
                        }
                    }
                }
                .arrows {
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    .second {
                        transform: scaleX(-1);
                    }
                }
            }
        }
        &.one {
            flex-direction: row-reverse;
            @include mobile {
                flex-direction: column;
            }
        }
        &.two {
            flex-direction: row;
            background-color: $green;
            @include mobile {
                flex-direction: column;
            }
        }
        &.three {
            flex-direction: row-reverse;
            background-color: $beige;
            @include mobile {
                flex-direction: column;
            }
            .copy-wrapper {
                h3 {
                    color: $brown;
                }
                p {
                    color: $brown;
                }
            }
            svg {
                path {
                    stroke: $brown;
                }
            }
        }
    }
}

.location-five {
    display: flex;
    padding: 32px;
    background-color: $brown;
    border-top: 1px solid $beige;
    justify-content: space-between;
    @include mobile {
        padding: 24px 0 0;
        flex-direction: column;
        gap: 24px;
    }
    .copy-wrapper {
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 500px;
        p{
            font-family: 'Verona Serial';
        }
        @include mobile {
            width: 100%;
            padding: 0 24px;
            h3{
                font-size: 18px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    .image-wrapper {
        background-size: cover;
        aspect-ratio: 411/296;
        height: 296px;
    }
}

.location-six {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(1, 1fr);
    aspect-ratio: 16/8;
    width: 100%;
    @include mobile {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .column-1 {
        grid-row: span 1;
        background-color: $orange;
        @include mobile {
            grid-row: auto;
            border-top: 1px solid $brown;
        }
        .copy-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 40px;
            position: relative;
            @include mobile {
                padding: 24px 32px;
            }
            h1 {
                font-size: 94px;
                color: $brown;
                font-family: "Verona Serial";
                span {
                    font-family: "Verona Serial";
                    font-size: 24px;
                }
                @include mobile {
                    font-size: 40px;
                    font-style: normal;
                    line-height: 48px;
                }
            }
            h2 {
                font-family: "Futura Std Book";
                font-weight: normal;
                @include mobile {
                    font-size: 16px;
                    line-height: 18px;
                }
                br {
                    display: none;
                }
            }
            img {
                position: absolute;
                right: 32px;
            }
            .circle {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $circle;
                transition: border-radius ease 0.1s 0.3s, width ease 0.3s;
                &.district {
                    background-color: $district;
                }
            }
            .circles-wrapper {
                display: flex;
                gap: 8px;
                padding: 16px 0;
            }
        }
        &:hover {
            .circle {
                transition: border-radius ease 0.1s, width ease 0.3s 0.2s;
                width: 40px;
                height: 20px;
                border-radius: 25px;
            }
        }
    }

    .column-2,
    .column-3 {
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        @include mobile {
            grid-template-rows: auto;
        }
    }

    .column-2 {
        grid-column: 2;
        @include mobile {
            grid-column: auto;
        }
    }

    .column-3 {
        grid-column: 3;
        @include mobile {
            grid-column: auto;
        }
    }
    .row {
        padding: 0 32px;
        display: flex;
        flex-direction: column;

        height: 100%;
        position: relative;
        border-right: 1px solid $brown;
        border-top: 1px solid $brown;

        .copy-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 40px 0 0;
            @include mobile {
                padding: 24px 0;
            }
            h1 {
                color: #302927;
                font-family: "Verona Serial";
                font-size: 48px;
                font-style: normal;
                font-weight: 400;
                line-height: 48px;
                span {
                    font-size: 24px;
                }
                @include mobile {
                    font-size: 40px;
                }
            }
            h2 {
                color: #302927;
                font-family: "Futura Std Book";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 120% */
                padding-bottom: 12px;
                @include mobile {
                    font-size: 16px;
                    padding-bottom: 8px;
                }
            }
        }
        .icons-wrapper {
            position: absolute;
            right: 32px;
            top: 32px;
            display: flex;
            gap: 16px;
        }
        &.beige {
            .copy-wrapper {
                h1 {
                    color: $stone;
                }
                h2 {
                    color: $stone;
                }
            }
        }

        &.one {
            background-color: $stone;
        }
        &.two {
            background-color: #bdbd54;
        }
        &.three {
            background-color: $brown;
        }
        &.four {
            background-color: $blue;
        }
        &.five {
            background-color: $green;
        }
        &.six {
            background-color: $brick;
        }
        &.seven {
            background-color: #4f562a;
        }
        &.eight {
            background-color: #b9d5d0;
        }

        .circles-wrapper {
            display: flex;
            gap: 8px;
            padding-top: 16px;
            .circle {
                transition: border-radius ease 0.1s 0.3s, width ease 0.3s;
                height: 16px;
                width: 16px;
                border-radius: 50%;

                background-color: $circle;
                &.district {
                    background-color: $district;
                }
                &.hammersmith {
                    background-color: $hammersmith;
                }
                &.bakerloo {
                    background-color: $bakerloo;
                }
                &.central {
                    background-color: $central;
                }
                &.victoria {
                    background-color: $victoria;
                }
                &.piccadilly {
                    background-color: $piccadilly;
                }
                &.metropolitan {
                    background-color: $metropolitan;
                }
                &.northern {
                    background-color: $northern;
                }
                &.elizabeth {
                    background-color: $elizabeth;
                }
            }
        }
        &:hover {
            .circle {
                transition: border-radius ease 0.1s, width ease 0.3s 0.2s;
                width: 40px;
                height: 16px;
                border-radius: 25px;
            }
        }
    }
}
