@import "../../scss/mixin.scss";

footer {
    background-image: url("../../../public/images/footer/1.jpg");
    background-size: cover;
    padding: 40px 32px;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    gap: 32px;
    @include mobile {
        padding: 32px 24px;
    }
    .wrapper {
        display: flex;
        @include mobile {
            flex-direction: column;
            gap: 32px;
        }
        .container {
            display: flex;
            flex-direction: column;
            width: 50%;
            align-items: flex-start;
            gap: 48px;
            @include mobile {
                width: 100%;
                gap: 32px;
            }
            .image-wrapper {
                height: 38px;
                display: flex;
                align-items: flex-end;
                
            }
            
                a{
                    text-decoration: none;
                    p{
                        text-decoration: none;
                    }
                }
            p {
                line-height: 150%;
                font-family: 'Futura Std Book';
            }
            .download {
                display: flex;
                flex-direction: column;
                gap: 16px;
                @include mobile{
                    gap: 16px;                 
                }
                h4 {
                    text-align: left;
                }
                .arrow-button {
                    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease, fill 0.3s ease;
                    &:hover {
                        background-color: $orange;
                        border-color: $brown;
                        p {
                            color: $orange;
                        }
                        svg {
                            path {
                                fill: $orange;
                            }
                        }
                    }
                }
            }
            .address-copy {
                display: flex;
                gap: 134px;
            }
            .agents {
                display: flex;
                flex-direction: column;
                gap: 48px;
                @include mobile {
                    gap: 32px;
                }
            }
            .agents-wrapper {
                display: flex;
                gap: 134px;
                justify-content: flex-end;
                width: 100%;
                @include mobile {
                    justify-content: flex-start;
                    gap: 64px;
                }
            }
            .email{
                &:hover{
                    text-decoration: underline;
                    color: $stone;
                }
            }
        }
    }
    h4{
        font-family: "Futura Std Book";
    }
    .bottom-wrapper {
        border-top: 1px solid $beige;
        padding-top: 32px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        @include mobile {                 
        }
        .flex-wrapper {
            display: flex;
            gap: 64px;
            padding-right: 64px;
            @include mobile{
                padding: 0;
                gap: 16px;
            }
            a {
                p {
                    text-decoration: underline;
                }
            }
        }
    }
}
