@import '../../scss/mixin.scss';


#gallery{
    height: 100%;
    width: 100%;
    .swiper-slide{
        height: 100%;
        width: 100%;
        .image-wrapper{
            aspect-ratio: 16/13;
            width: 100%;
            background-size: cover;
            background-position: bottom;
        }
    }
}