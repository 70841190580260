@import "./mixin.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contain {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

body {
    background-color: $stone;
}

.arrow-button {
    display: flex;
    width: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 37px;
    text-decoration: none;
    border: 1px solid $brown;
    cursor: pointer;
    h5 {
        font-family: "Futura Std Book";
    }
    p {
        font-family: "Futura Std Book";
    }
    &.btn-brown {
        background-color: $brown;

        h5 {
            color: $orange;
        }
        svg {
            path {
                fill: $orange;
            }
        }
    }
    &.btn-stone {
        background-color: $stone;
        h5 {
            color: $brown;
        }
        svg {
            path {
                fill: $brown;
            }
        }
    }
    &.btn-footer {
        border-color: $stone;
        h5 {
            color: $brown;
        }
        svg {
            path {
                fill: $stone;
            }
        }
        &:hover {
            p {
                color: $brown !important;
            }
            svg {
                path {
                    fill: $brown !important;
                }
            }
        }
    }
    p {
        font-size: 12px;
    }
}

.intro-overlay {
    width: 100vw;
    height: 100vh;
    background-color: $brown;
    top: 0;
    position: absolute;
    z-index: 10;
}

.image-wrapper {
    position: relative;
    .image-caption {
        position: absolute;
        bottom: 32px;
        left: 32px;

        font-family: "Futura Std Book";
        color: $stone;
        @include mobile {
            font-size: 16px;
        }
    }
}




.caption{
    position: absolute;
    bottom: 16px;
    right: 24px;
    font-size: 16px;
    color: $stone;
    font-family: 'Verona Serial';
    @include mobile{
        display: none;
    }
    &.flip{
        right: unset;
        left: 24px;
    }
}


.image-wrapper{
    position: relative;
}