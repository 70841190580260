@import "../../scss/mixin.scss";

.floorplan-table-section {
    display: flex;
    width: 100%;

    @include mobile {
        flex-direction: column-reverse;
    }
    .table {
        background-color: $beige;
        width: calc(100% / 3 * 2);
        @include mobile {
            width: 100%;
        }
        .row {
            width: 100%;
            display: flex;
            padding: 32px 48px 32px 16px;
            gap: 32px;
            border-bottom: 1px solid $brown;
            @include mobile {
                padding: 16px 24px;
            }
            &:not(.brown):not(.orange):not(.disabled) {
                &:hover {
                    cursor: pointer;
                    background-color: rgba($brown, 0.8);

                    transition: background-color 0.3s ease;
                    h6 {
                        color: $stone;
                    }
                }
            }
            .cell {
                width: 25%;
                text-align: right;

                h6 {
                    text-align: right;
                    width: 100%;
                    @include mobile {
                        font-size: 12px;
                    }
                }
            }
            :first-child {
                width: 50%;
                text-align: left;
                h6 {
                    text-align: left;
                    width: 100%;
                }
            }
            &.brown {
                background-color: $brown;
                h6 {
                    color: $stone;
                    font-size: 12px;
                }
            }
            &.orange {
                background-color: $orange;
            }
        }
    }
    .image-wrapper {
        display: flex;
        width: calc(100% / 3);
        background-size: cover;
        background-position: center;
    }
}

.floorplans {
    display: flex;
    padding: 80px 32px;
    background-color: $brick;
    flex-direction: column;
    @include mobile {
        padding: 24px;
        position: relative;
    }
    h3 {
        color: $brown;
    }
    .title-wrapper {
        padding: 0 0 80px;
        @include mobile {
            padding: 0 0 24px;
        }
    }
    .floorplans-wrapper {
        display: flex;
        gap: 126px;
        @include mobile {
            flex-direction: column;
            gap: 0;
        }
        .floorplan-detail {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            width: calc(100% / 3);
            @include mobile {
                width: 100%;
            }
            .copy-wrapper {
                gap: 32px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                height: 100%;
                .title-container {
                    display: flex;
                    flex-direction: column;
                    gap: 32px;
                    h3 {
                        font-family: "Futura Std Book";
                        @include mobile {
                            font-size: 20px;
                        }
                    }
                }
                .content-wrapper {
                    display: flex;
                    gap: 48px;
                    @include mobile {
                        display: none;

                    }
                    p {
                        padding-bottom: 32px;
                        color: $brown;
                        font-family: "Futura Std Book";
                    }
                    h3 {
                        padding-bottom: 16px;
                        font-family: "Verona Serial";
                    }
                    h5{
                        font-size: 22px;
                        color: $brown;
                        text-align: left;
                    }
                }
            }
        }
        .nav-wrapper {
            display: flex;
            gap: 32px;
            align-items: flex-end;
            .buttons {
                display: flex;
                flex-direction: column;
                gap: 4px;
                @include mobile {
                    display: none;
                }
                .arrow-button {
                    background-color: $beige;
                    transition: background-color 0.3s ease, color 0.3s ease;
                    p {
                        color: $brown;
                        font-family: "Futura Std Book";
                    }
                    &:hover {
                        background-color: $brown;
                        p {
                            color: $orange;
                        }
                        svg {
                            path {
                                fill: $orange;
                            }
                        }
                    }

                    &.active {
                        background-color: $brown;
                        p {
                            color: $orange;
                        }
                        svg {
                            path {
                                fill: $orange;
                            }
                        }
                    }
                }
            }

            .key {
                display: flex;
                flex-direction: column;
                gap: 16px;
                @include mobile {
                    position: absolute;
                    top: 24px;
                    right: 32px;
                    gap: 8px;
                }
                .row {
                    display: flex;
                    gap: 16px;
                    p {
                        color: $brown;
                        font-family: "Futura Std Book";
                        @include mobile {
                            font-size: 12px;
                        }
                    }
                    .circle {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-color: $stone;
                        border: 1px solid $brown;
                        @include mobile {
                            width: 12px;
                            height: 12px;
                        }
                        &.lime {
                            background-color: #bdbd54;
                        }
                        &.beige {
                            background-color: #dcc6ad;
                        }
                        &.cyan{
                            background-color: #cfdae4;
                        }

                        &.cycle{
                            background-color: #b49a7c;
                        }
                    }
                }
            }
        }

        #floorplans {
            width: calc(100% / 3 * 2);
            max-width: 1000px;
            @include mobile {
                width: 100%;
            }
            .swiper-slide {
                height: 50vh;
                padding-right: 32px;
                @include mobile {
                    width: 100%;
                    padding-right: 0;
                    height: 40vh;
                }
                img {
                    height: 100%;
                    @include mobile{
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

.content-wrapper-mb {
    display: flex;
    gap: 48px;
    display: none;
    @include mobile {
        display: flex;
        padding-top: 32px;
        min-height: 150px;        
    }
    p {
        padding-bottom: 16px;
        color: $brown;
        font-family: "Futura Std Book";
    }
    h3 {
        padding-bottom: 8px;
        font-family: "Verona Serial";
    }
    h5{
        font-size: 18px;
        color: $brown;
        text-align: left;
        @include mobile{
            font-size: 20px;
        }
    }
}

.mobile-arrows {
    display: none;
    justify-content: space-between;
    padding-top: 32px;
    
    @include mobile {
        display: flex;
        svg {
            width: 30px;
        }
    }
    .arrow-prev {
        transform: scaleX(-1);
    }
}
