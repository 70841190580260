@import "../../scss/mixin.scss";

.building-one {
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .video {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        position: absolute;
    }

    .intro-overlay {
        width: 100vw;
        height: 100vh;
        background-color: $brown;
        top: 0;
        position: absolute;
        z-index: 10;
    }
    .circle {
        z-index: 2;
        width: 600px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: $brown;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .copy {
            margin-top: calc(-59px / 2);
        }
        @include mobile {
            width: 400px;
            h1{
                line-height:46px;
                padding-top: 10px;
            }
            .copy {
                margin-top: -10px;
            }
        }
    }
}

.building-two {
    background-color: $green;
    height: 100%;
    display: flex;
    justify-content: center;
    
    .text-wrapper {
        width: 800px;
        padding: 64px 32px 0;
        height: 200vh;
    }
    .text-to-animate {
        text-align: center;
        height: 100%;
    }
}

.building-three {
    width: 100%;
    aspect-ratio: 16/9;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    @include mobile{   
        aspect-ratio: 4/3;
    }
}

.building-four {
    padding: 80px 32px;
    display: flex;
    justify-content: space-between;
    @include mobile {
        flex-direction: column;
        padding: 32px 0;
        gap: 32px;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 32px;

        h3,
        p {
            color: $brown;
            @include mobile {
                padding: 0 24px;
            }
        }
        p {
            max-width: 400px;            
            font-family: 'Verona Serial';
            @include mobile {
                width: 100%;
            }
        }
    }
    .image-wrapper {
        width: 60%;
        aspect-ratio: 3/2;
        background-size: cover;
        background-position: bottom;
        @include mobile {
            width: 100%;
            
                aspect-ratio: 4/3;
            
        }
    }
}

.building-five {
    display: flex;
    justify-content: center;
    padding: 80px 32px;
    border-top: 1px solid $brown;
    @include mobile {
        padding: 32px 0px 0;
    }
    .copy-wrapper{
        @include mobile{
            padding: 0 24px;
        }
    }
    .image-wrapper {
        width: 100%;
        aspect-ratio: 16/9;
        background-size: cover;
        @include mobile{
            aspect-ratio: 4/3;
        }
    }
}

.building-six {
    display: flex;
    justify-content: space-between;
    padding: 0 32px 52px;
    @include mobile {
        flex-direction: column;
        padding: 32px 24px;
        gap: 32px;
    }
    h3,
    p {
        color: $brown;
        max-width: 400px;
        font-family: 'Verona Serial';
    }
}

.building-seven {
    padding: 0 0 80px;
    @include mobile {
        padding: 0 24px 32px;
    }
}

.building-eight {
    background-color: $brick;
    .title-wrapper {
        display: flex;
        padding: 32px;
        gap: 126px;
        @include mobile {
            flex-direction: column;
            padding: 32px 24px;
            gap: 32px;
        }
        .button-wrapper {
            display: flex;
            gap: 24px;

            .arrow-button {
                background-color: $beige;
                transition: all 0.5s ease;
                p {
                    color: $brown;
                }
                svg {
                    path {
                        fill: $brown;
                    }
                }
                &:hover {
                    background-color: $brown;
                    p {
                        color: $orange;
                    }
                    svg {
                        path {
                            fill: $orange;
                        }
                    }
                }
                &.btn-brown {
                    background-color: $brown;
                    p {
                        color: $orange;
                    }
                    svg {
                        path {
                            fill: $orange;
                        }
                    }
                }
            }
        }
    }
    .orange {
        color: $orange;
    }
    .specification {
        display: flex;
        flex-direction: column;
        background-color: $brown;
        width: 100%;        
        .row {
            display: flex;
            border-bottom: 1px solid $stone;
            padding: 40px 64px 40px 32px;
            justify-content: space-between;
            width: 100%;            
            @include mobile {
                padding: 24px 24px;
                flex-direction: column;
            }
            @include tablet {
                padding: 24px 32px;
                gap: 24px;
                flex-direction: column;
            }
            h3 {
                display: flex;
                width: 344px;
                align-self: flex-start;
                font-family: 'Verona Serial';
                @include mobile {
                    padding-bottom: 32px;
                }
            }
            .icon-wrapper {
                display: flex;
                gap: 64px;
                flex-wrap: wrap;
                justify-content: space-between;                
                
                .icon {
                    display: flex;
                    flex-direction: column;
                    width: 160px;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    gap: 32px;
                    @include mobile {
                        width: 120px;
                        gap: 16px;
                    }
                    p {
                        color: $stone;
                        font-family: 'Verona Serial';
                    }
                    .image-wrapper {
                        height: 50px;
                    }
                }
            }
        }

        &.sustain {
            background-color: $green;
            .p{
                font-family: 'Verona Serial';
            }
            h3{
                font-family: 'Verona Serial';
            }
            .icon-wrapper {
                .icon {
                    width: 300px;
                }
            }
        }
    }
}

.building-nine {
    padding: 80px 32px;
    background-color: $green;
    display: flex;
    gap: 80px;
    @include mobile {
        flex-direction: column;
        gap: 32px;
        padding: 32px 0px;
    }
    .image-wrapper {
        width: 60%;
        aspect-ratio: 855/655;
        background-size: cover;
        @include mobile {
            width: 100%;
            aspect-ratio: 4/3;
        }
    }
    .copy-wrapper {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 32px 0;
        @include mobile {
            width: 100%;
            padding: 16px 24px;
        }
        p{
            max-width: 400px;
            font-family: 'Verona Serial';
            @include mobile{
                width: 100%;
            }
        }
    }

    &.two {
        flex-direction: row-reverse;
        background-color: $beige;
        padding: 80px 32px 0;
        @include mobile {
            padding: 32px 0px 0;
            flex-direction: column-reverse;
        }
        .copy-wrapper {
            h3 {
                color: $brown;
            }
            p {
                color: $brown;
                max-width: 400px;
                font-family: 'Verona Serial';
                @include mobile {
                    width: 100%;
                }
            }
        }
        
    }
}

.building-ten {
    padding: 0 32px 80px;
    background-color: $green;
    @include mobile {
        padding: 0 0px 0;
    }
    .image-wrapper {
        width: 100%;
        aspect-ratio: 16/9;
        background-size: cover;
        @include mobile {
            aspect-ratio: 4/3;
        }
    }
}

.building-eleven {
    background-color: $beige;
    padding: 80px 0;
    @include mobile {
        padding: 32px 24px;
    }
}
