@import "../../scss/mixin.scss";

#location-gallery-one, #location-gallery-two, #location-gallery-three {
    height: 100%;
    width: 60%;
    @include mobile {
        width: 100%;
    }

    .swiper-wrapper {
        height: 100%;
        width: 60%;
        .swiper-slide {
            width: 100%;
        }
        .image-wrapper {
            aspect-ratio: 16/12;
            width: 100%;
            background-size: cover;
            background-position: bottom;
        }
    }    
}

.arrows{
    svg{
        path{
            transform: fill 0.5s ease;
            &:hover{
                fill: $orange;
            }
        }
    }
}
