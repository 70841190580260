$stone: #e6e0cc;
$brown: #302927;
$green: #2c321d;
$brick: #d9b795;
$orange: #ec6332;
$beige: #dcc6ad;
$blue: #0b5276;
$circle: #f2c200;
$district: #007a33;
$central: #d10d02;
$piccadilly: #2e2dbb;
$metropolitan: #781642;
$northern: #000000;
$waterloo: #8b8c8d;
$hammersmith: #ffacbc;
$bakerloo: #7c4c2c;
$victoria: #2186c0;
$elizabeth: #695a91;

@mixin large-desktop {
    @media (min-width: 1500px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: 1024px) and (max-width: 1200px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@font-face {
    font-family: "Futura Std Book";
    src: url("../../public/fonts/FuturaStd-Book.woff2") format("woff2"),
        url("../../public/fonts/FuturaStd-Book.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Futura Std";
    src: url("../../public/fonts/FuturaStd-Light.woff2") format("woff2"),
        url("../../public/fonts/FuturaStd-Light.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Verona Serial";
    src: url("../../public/fonts/VeronaSerial.woff2") format("woff2"),
        url("../../public/fonts/VeronaSerial.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verona Serial';
    src: url('../../public/fonts/VeronaSerial-Light.woff2') format('woff2'),
        url('../../public/fonts/VeronaSerial-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Verona Serial";
    src: url("../../public/fonts/VeronaSerial-Medium.woff2") format("woff2"),
        url("../../public/fonts/VeronaSerial-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

h1 {
    color: #e6e0cc;
    text-align: center;
    font-family: "Futura Std";
    font-size: 42px;
    font-style: normal;
    font-weight: 300;
    line-height: 59px; /* 140.476% */
    @include mobile(){
        font-size: 32px;
    }
}

h2 {
    color: #302927;
    font-family: "Verona Serial";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px; /* 115.625% */
    @include mobile(){
        font-size: 28px;
    }
}

h3 {
    color: $stone;
    font-family: "Verona Serial";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px; /* 135.714% */
    @include mobile(){
        font-size: 24px;
        line-height: 135%;
    }
}

h4 {
    color: $stone;
    text-align: center;
    font-family: "Verona Serial";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 59px; /* 368.75% */
    @include mobile(){
        font-size: 14px;
        line-height: 18px;
    }
}

h5 {
    color: $stone;
    text-align: center;
    font-family: "Verona Serial";
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px; /* 116.667% */
    @include mobile(){
        font-size: 20px;
        line-height: 116%;
    }
}

h6 {
    color: #302927;
    font-family: "Verona Serial";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    @include mobile(){
        font-size: 16px;
        line-height: 120%;
    }
}

p {
    color: #e6e0cc;
    font-family: "Futura Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    line-height: 125%;
    @include mobile(){
        font-size: 14px;
    }
}

b {
    font-weight: bold;
}

.stone {
    color: $stone;
}

.brown {
    color: $brown;
}
