@import "../../scss/mixin.scss";

.availability-one {
    height: 100vh;
    background-size: cover;
    background-position: center 25%;
    display: flex;
    justify-content: center;
    align-items: center;

    .circle {
        width: 600px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        background-color: $brown;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .copy {
            margin-top: calc(-59px / 2);
        }
        @include mobile {
            width: 400px;
            h1 {
                line-height: 46px;
                padding-top: 10px;
            }
            .copy {
                margin-top: -10px;
            }
        }
    }
}

.availability-two {
    background-color: $brick;
    height: 100%;
    display: flex;
    justify-content: center;

    .text-wrapper {
        width: 800px;
        padding: 64px 32px 0;
        height: 200vh;
    }
    .text-to-animate {
        text-align: center;
        height: 100%;
        color: $brown;
    }
}

.availability-three {
}

.availability-four {
    position: relative;
    z-index: 10;
}

.availability-five {
    background-color: $brick;
    padding: 40px 0;
    @include mobile {
        padding: 24px;
    }
}
